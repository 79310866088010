import { ModalDialog } from '@/scripts/theme/modal-dialog'
import {
	getAttributeOrThrow,
	pauseAllMedia,
	qsaOptional,
	qsaRequired,
	qsOptional,
	qsRequired,
	trapFocus,
} from '@/scripts/core/global'
import { type uCoastWindow } from '@/scripts/setup'
import { type CartDrawer } from '@/scripts/cart/cart-drawer'
import { type PickupAvailability } from '@/scripts/optional/pickup-availability'
import { type ProductModal } from '@/scripts/product/product-modal'
import { ATTRIBUTES, SELECTORS } from '@/scripts/core/global'

declare let window: uCoastWindow

export class QuickAddModal extends ModalDialog {
	static override htmlSelector = 'quick-add-modal'
	productElement?: HTMLElement
	modalContent: HTMLElement
	productId: string
	constructor() {
		super()
		this.modalContent = qsRequired('[id^="QuickAddInfo-"]', this)
		this.productId = getAttributeOrThrow('data-uc-product-id', this)
	}

	override connectedCallback() {}

	override hide(preventFocus = false) {
		const checkedOptions = qsaOptional<HTMLInputElement>('input[type="radio"]:checked', this)
		checkedOptions?.forEach((option) => (option.checked = false))
		const cartNotification = qsRequired<CartDrawer>('cart-drawer')
		if (cartNotification && this.openedBy) cartNotification.setActiveElement(this.openedBy)
		window.setTimeout(() => {
			this.modalContent.innerHTML = ''
		}, 601)

		if (preventFocus) this.openedBy = undefined
		super.hide()
	}

	makeInstanceOptionsChecked() {
		try {
			const otherQuickAdds = qsaOptional<QuickAddModal>(
				`quick-add-modal[open]:not([data-uc-product-id="${this.productId}"])`
			)
			otherQuickAdds?.forEach((quickAdd) => {
				quickAdd.makeInstanceOptionsUnchecked()
				quickAdd.hide()
			})
			const checkedOptions = qsaOptional('input[type="radio"]:checked', this)
			if (checkedOptions) {
				return
			}
			const checkedAttrOptions = qsaRequired<HTMLInputElement>('input[type="radio"][checked]', this)
			checkedAttrOptions.forEach((option) => {
				option.checked = true
			})
		} catch (e) {
			this.hide()
		}
	}

	makeInstanceOptionsUnchecked() {}

	override show(opener: HTMLElement) {
		if (this.preloaded) {
			this.makeInstanceOptionsChecked()
			this.setAttribute('open', '')
			trapFocus(this, qsRequired('[role="dialog"]', this))
			pauseAllMedia()
		} else {
			opener.setAttribute('aria-disabled', 'true')
			opener.setAttribute(ATTRIBUTES.loading, '')
			qsRequired(SELECTORS.loadingOverlaySpinner, opener).classList.remove('hidden')

			fetch(getAttributeOrThrow('data-product-url', opener))
				.then((response) => response.json())
				.then((responseText) => {
					const responseHTML = new DOMParser().parseFromString(responseText['quick-add-card'], 'text/html')
					this.productElement = qsRequired('[data-uc-quick-add-card]', responseHTML.documentElement)
					this.removeDOMElements()
					this.setInnerHTML(this.modalContent, this.productElement.innerHTML)
				})
				.finally(() => {
					this.makeInstanceOptionsChecked()
					opener.removeAttribute('aria-disabled')
					opener.removeAttribute(ATTRIBUTES.loading)
					qsRequired(SELECTORS.loadingOverlaySpinner, opener).classList.add('hidden')
					this.setAttribute('open', '')
					trapFocus(this, qsRequired('[role="dialog"]', this))
					pauseAllMedia()
				})
		}
	}

	override preload() {
		if (this.preloaded === true) {
			return
		}
		if (!this.preloadEl) {
			throw new Error('this.preloadEl is undefined')
		}

		const productUrl = getAttributeOrThrow('data-product-url', this.preloadEl)
		fetch(productUrl)
			.then((response) => response.json())
			.then((responseText) => {
				this.preloaded = true
				const responseHTML = new DOMParser().parseFromString(responseText['quick-add-card'], 'text/html')
				this.productElement = qsRequired('[data-uc-quick-add-card]', responseHTML.documentElement)
				this.removeDOMElements()
				this.setInnerHTML(this.modalContent, this.productElement.innerHTML)
				this.makeInstanceOptionsChecked()
			})
			.catch((error) => {
				this.preloaded = false
				console.log(error)
			})
	}

	setInnerHTML(element: HTMLElement, html: string) {
		element.innerHTML = html

		// Reinjects the script tags to allow execution. By default, scripts are disabled when using element.innerHTML.
		element.querySelectorAll('script').forEach((oldScriptTag: HTMLScriptElement) => {
			if (!oldScriptTag.parentNode) throw new Error('oldScriptTag.parentNode is null, cannot replace script')
			const newScriptTag = document.createElement('script')
			Array.from(oldScriptTag.attributes).forEach((attribute) => {
				newScriptTag.setAttribute(attribute.name, attribute.value)
			})
			newScriptTag.appendChild(document.createTextNode(oldScriptTag.innerHTML))
			oldScriptTag.parentNode.replaceChild(newScriptTag, oldScriptTag)
		})
	}

	removeDOMElements() {
		if (!this.productElement) throw new Error('this.removeDOMElements called without this.productElement')
		qsOptional<PickupAvailability>('pickup-availability', this.productElement)?.remove()
		qsOptional<ProductModal>('product-modal', this.productElement)?.remove()
		const modalDialogs = qsaOptional<ModalDialog>('modal-dialog', this.productElement)
		if (modalDialogs) {
			modalDialogs.forEach((modal) => modal.remove())
		}
	}
}
